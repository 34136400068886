import { ref, computed, reactive, watch, nextTick, onMounted, readonly } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { router, usePage } from '@inertiajs/vue3';
import debounce from 'lodash.debounce';
import { useToast } from 'vue-toastification';
import { formatMessage, can } from './shared';

export function useFilterFetch() {
    // get current state from url
    // update url when mutating state
    // do not return items. It will be returned by page redirection

    const urlSearchParams = useUrlSearchParams('history');

    const order = computed({
        get() {
            return {
                by: urlSearchParams.orderBy,
                direction: urlSearchParams.orderDirection,
            };
        },
        set({ by, direction }) {
            router.get(location.pathname, { ...urlSearchParams, orderBy: by, orderDirection: direction });
        },
    });

    const q = computed({
        get() {
            return urlSearchParams.q;
        },
        set: debounce(val => {
            router.get(
                location.pathname,
                { ...urlSearchParams, page: 1, q: val || undefined },
                { preserveState: true },
            );
        }, 500),
    });

    const page = computed({
        get() {
            return urlSearchParams.page ? Number(urlSearchParams.page) : 1;
        },
        set(val) {
            router.get(location.pathname, { ...urlSearchParams, page: val });
        },
    });

    return {
        order,
        q,
        page,
    };
}

export function useModal(initOpen = false) {
    const isOpen = ref(initOpen);

    return reactive({
        isOpen,
        open: () => (isOpen.value = true),
        close: () => (isOpen.value = false),
    });
}

export function useFlashMessages() {
    const page = usePage();
    const toast = useToast();

    watch(() => page.props.flash?.notification, notification => {
        if (!notification) return;
        const { type = 'success', content } = notification;

        if (type && content) {
            toast[type](content);
        }
    }, { immediate: true });
}

export class Paywall extends Error {
    constructor(message) {
        super(message);
        this.name = 'Paywall';
    }
}

export function usePricingTable() {
    const products = ref([]);
    const isLoading = ref(false);

    async function load() {
        isLoading.value = true;
        try {
            const response = await fetch(route('pricing'));
            products.value = await response.json();
        } finally {
            isLoading.value = false;
        }
    }

    return {
        load,
        isLoading: readonly(isLoading),
        products: readonly(products),
    }
}
export function usePermissions() {
    const page = usePage();
    const user = computed(() => page.props.auth?.user);

    return {
        user,
        can(ability) {
            if (!user.value || !user.value.allPermissions) return false;
            return can(user.value, ability);
        },
    };
}
