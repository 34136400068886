<script setup>
import { CheckIcon } from '@heroicons/vue/24/solid';
import { computed, ref } from 'vue';
import Spinner from './Spinner.vue';

const props = defineProps({
    products: { type: Array, required: true },
    subscribedPriceId: { type: String, required: false },
    accessUntil: { type: String, required: false },
    isLoading: { type: Boolean, default: false }
});

const isCanceled = computed(() => {
    return !!props.accessUntil;
});

/**
 * @type {import('vue').Ref<"monthly" | "yearly">}
 */
const pricingInterval = ref('monthly');

const monthlyPrices = computed(() => {
    return props.products.filter(product => 
        product.prices.some(price => price.recurring?.interval === 'month')
    ).map(product => {
        const { prices, ...rest } = product;
        return {
            ...rest,
            price: prices.find(price => price.recurring?.interval === 'month')
        }
    });
});

const yearlyPrices = computed(() => {
    return props.products.filter(product => 
        product.prices.some(price => price.recurring?.interval === 'year')
    ).map(product => {
        const { prices, ...rest } = product;
        return {
            ...rest,
            price: prices.find(price => price.recurring?.interval === 'year')
        }
    });
});

</script>

<template>
    <div v-if="isLoading" class="flex justify-center items-center text-primary">
        <Spinner class="animate-spin text-current h-8" />
        <span class="text-current ml-2">Loading pricing...</span>
    </div>
    <div v-else class="bg-white px-4 sm:px-6 lg:px-8">
        <!-- Billing Toggle -->
        <div class="flex justify-center items-center mb-8">
            <span class="text-gray-500">Monthly</span>
            <button 
                class="relative mx-4 inline-flex h-8 aspect-[44/24] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none bg-primary"
                role="switch"
                type="button"
                @click="pricingInterval = pricingInterval === 'monthly' ? 'yearly' : 'monthly'"
            >
                <span 
                    class="pointer-events-none inline-block h-full aspect-square transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    :class="pricingInterval === 'yearly' ? 'translate-x-full' : 'translate-x-0'"
                ></span>
            </button>
            <span class="text-gray-500">Yearly</span>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-4 gap-8 mt-12">
            <template v-for="product in (pricingInterval === 'yearly' ? yearlyPrices : monthlyPrices)" :key="product.id">
                <div class="rounded-lg p-8 flex flex-col" :class="product.price.id === subscribedPriceId ? 'border-2 border-primary' : 'border border-gray-200'">
                    <!-- Header -->
                    <div class="mb-6">
                        <h3 class="text-2xl font-bold text-gray-900">{{ product.name }}</h3>
                        <p class="mt-2 text-gray-500">{{ product.description }}</p>
                    </div>

                    <!-- Pricing -->
                    <div class="mb-6">
                        <div class="flex items-baseline">
                            <template v-if="!product.name.toUpperCase().includes('Enterprise'.toUpperCase())">
                                <span class="text-4xl font-bold text-primary">€{{ product.price.unit_amount / 100 }}</span>
                                <span class="ml-2 text-gray-500">/{{ product.price.recurring.interval }}</span>
                            </template>
                            <template v-else>
                                <!--  -->
                            </template>
                        </div>
                    </div>

                    <!-- Features -->
                    <div class="space-y-4 grow">
                        <template v-for="feature in product.features" :key="feature.name">
                            <div class="flex items-start">
                                <div class="flex-shrink-0">
                                    <CheckIcon class="h-6 w-6 text-primary" />
                                </div>
                                <p class="ml-3 text-gray-500">{{ feature.name }}</p>
                            </div>
                        </template>
                    </div>

                    <!-- CTA Button -->
                    <div class="mt-8">
                        <a 
                            :href="product.name.toUpperCase().includes('Enterprise'.toUpperCase()) ? route('contact.show') : (subscribedPriceId === product.price.id ? null : product.price.checkout_url)" 
                            :aria-disabled="subscribedPriceId === product.price.id && !product.name.toUpperCase().includes('Enterprise'.toUpperCase())"
                            :class="[
                                'w-full text-white rounded-md py-2 px-4 transition duration-150 ease-in-out text-center block',
                                subscribedPriceId === product.price.id && !isCanceled && !product.name.toUpperCase().includes('Enterprise'.toUpperCase())
                                    ? 'bg-gray-400 cursor-not-allowed' 
                                    : 'bg-primary hover:bg-purple-700'
                            ]"
                        >
                            {{ 
                                product.name.toUpperCase().includes('Enterprise'.toUpperCase())
                                    ? 'Contact us'
                                    : (subscribedPriceId === product.price.id 
                                        ? isCanceled
                                            ? `Resubscribe`
                                            : 'Current Plan'
                                        : subscribedPriceId 
                                            ? 'Change'
                                            : 'Subscribe')
                            }}
                        </a>
                        <p v-if="subscribedPriceId === product.price.id && isCanceled" 
                            class="mt-2 text-sm text-gray-500 text-center">
                            Access until {{ new Date(accessUntil).toLocaleDateString() }}
                        </p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
