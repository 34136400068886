<script setup>
import { ref, watchEffect } from 'vue';
import { Link, useForm } from '@inertiajs/vue3';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/vue/24/solid';
import { BackdropModal } from '@goodvibes/kudoslink';
import { Card, Spinner, TertiaryButton, TextInput, PricingTable } from '@/Components';
import { usePricingTable } from '@/composables';
import { Pattern } from '../../images/backgrounds';
import { useToast } from 'vue-toastification';
import { usePaywallModal } from '@/usePaywallModal';

const { isOpen, countryCode, reason, userTier, currentSubscription, hasBillingAccess, close } = usePaywallModal();

const pricingTable = usePricingTable();

const pricingTableRef = ref(null);

// load prices on demand - only when the pricing table is mounted
watchEffect(function onPricingTableMounted() {
    if (pricingTableRef.value) {
        pricingTable.load();
    }
});

// maxmans
const toast = useToast();
const couponForm = useForm({
    code: '',
});

function redeemCoupon() {
    couponForm.get(route('coupons.redeem', { code: couponForm.code }), {
        preserveState: true,
        onSuccess: () => {
            toast.success('Coupon applied!');
            isOpen.value = false;
        },
    });
}
</script>

<template>
  <BackdropModal class="!z-[10001] overflow-scroll" :show="isOpen" @close="close">
        <div class="flex justify-center items-center h-full gap-y-4">
            <!-- Global paywall banner -->
            <template v-if="countryCode !== 'sk'">
                <template v-if="reason === 'roleplay'">
                    <Card 
                        v-if="userTier === 'Demo'"
                        class="flex flex-col justify-between gap-y-14 w-full max-w-3xl mx-auto"
                        :bgImage="Pattern"
                    >
                        <div class="flex flex-col">
                            <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">Sign-up now and enjoy 10 free roleplays each month!</h1>
                        </div>
                        
                        <div class="flex max-w-3xl mx-auto items-center gap-x-4">
                            <TertiaryButton :as="Link" :href="route('register')">Sign up here</TertiaryButton>
                        </div>
                    </Card>
                    <template v-else>
                        <Card 
                            v-if="hasBillingAccess"
                            class="flex flex-col justify-between gap-y-14 w-full mx-auto"
                            :bgImage="Pattern"
                        >
                            <div class="flex flex-col">
                                <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">You’ve reached your plan’s limit.</h1>
                                <p class="mt-8 md:text-2xl">But don’t worry, upgrading allows you to play and create more roleplays.</p>
                            </div>
                            
                            <PricingTable 
                                ref="pricingTableRef" 
                                class="py-12 rounded-lg" 
                                :isLoading="pricingTable.isLoading.value" 
                                :products="pricingTable.products.value"
                                :subscribedPriceId="currentSubscription?.stripe_price"
                                :accessUntil="currentSubscription?.ends_at"
                            />
                        </Card>
                        <Card 
                            v-else
                            class="flex flex-col justify-between gap-y-14 w-full max-w-3xl mx-auto"
                            :bgImage="Pattern"
                        >
                            <div class="flex flex-col">
                                <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">You’ve hit the maximum roleplays you can play on your current plan.</h1>
                                <p class="mt-8 md:text-2xl">We’ll notify your admin about upgrading to unlock more roleplays.</p>
                            </div>
                        </Card>
                    </template>
                </template>

                <template v-else-if="reason === 'roleplayBuilder'">
                    <Card 
                        v-if="userTier === 'Demo'"
                        class="flex flex-col justify-between gap-y-14 w-full max-w-3xl mx-auto"
                        :bgImage="Pattern"
                    >
                        <div class="flex flex-col">
                            <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">Want to create your own roleplay?</h1>
                        </div>
                        
                        <div class="flex max-w-3xl mx-auto items-center gap-x-4">
                            <TertiaryButton :as="Link" :href="route('register')">Sign up now</TertiaryButton>
                        </div>
                    </Card>
                    <template v-else>
                        <Card 
                            v-if="hasBillingAccess"
                            class="flex flex-col justify-between gap-y-14 w-full mx-auto"
                            :bgImage="Pattern"
                        >
                            <div class="flex flex-col">
                                <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">You’ve reached your plan’s limit.</h1>
                                <p class="mt-8 md:text-2xl">But don’t worry, upgrading allows you to play and create more roleplays.</p>
                            </div>
                            
                            <PricingTable 
                                ref="pricingTableRef" 
                                class="py-12 rounded-lg" 
                                :isLoading="pricingTable.isLoading.value" 
                                :products="pricingTable.products.value" 
                                :subscribedPriceId="currentSubscription?.stripe_price"
                                :accessUntil="currentSubscription?.ends_at"
                            />
                        </Card>
                        <Card 
                            v-else
                            class="flex flex-col justify-between gap-y-14 w-full max-w-3xl mx-auto"
                            :bgImage="Pattern"
                        >
                            <div class="flex flex-col">
                                <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">You’ve hit the maximum roleplay builds for your current plan.</h1>
                                <p class="mt-8 md:text-2xl">We’ll notify your admin about upgrading to unlock more roleplays.</p>
                            </div>
                        </Card>
                    </template>
                </template>

                <!-- Generic paywall banner -->
                <template v-else>
                    <Card 
                        class="flex flex-col justify-between gap-y-14 w-full mx-auto"
                        :bgImage="Pattern"
                    >
                        <div class="flex flex-col">
                            <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">Looks like you hit your limits.</h1>
                            <p class="mt-8 md:text-2xl">But don't worry, you can upgrade your account to get more.</p>
                        </div>
                        
                        <PricingTable 
                            ref="pricingTableRef" 
                            class="py-12 rounded-lg" 
                            :isLoading="pricingTable.isLoading.value" 
                            :products="pricingTable.products.value" 
                            :subscribedPriceId="currentSubscription?.stripe_price"
                            :accessUntil="currentSubscription?.ends_at"
                        />
                    </Card>
                </template>
            </template>

            <!-- Slovak paywall banner -->
            <template v-else>
                <Card
                    class="flex flex-col justify-between gap-y-14 w-full max-w-3xl mx-auto"
                    :bgImage="Pattern"
                >
                    <div class="flex flex-col">
                        <h1 class="text-2xl md:text-4xl lg:text-5xl font-bold">Ready to unlock Personeo’s full potential?</h1>
                        <p class="text-md md:text-xl mt-4 md:mt-6">Gain advanced features by connecting with our Slovak partner — Maxman Consultants.</p>
                        <div class="flex flex-wrap md:flex-nowrap gap-4 md:justify-around mt-10">
                            <div class="flex flex-col gap-y-2">
                                <a href="mailto:balis@maxman-consultants.com" class="flex gap-x-3 items-center mx-auto">
                                    <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                        <EnvelopeIcon class="fill-white w-5"/>
                                    </div>
                                    <div class="text-lg font-bold">balis@maxman-consultants.com</div>
                                </a>
                            </div>
                            <div class="flex flex-col gap-y-2">
                                <a href="tel:+421915718688" class="flex gap-x-3 items-center mx-auto">
                                    <div class="p-2 aspect-square rounded-full bg-gradient-to-br from-[#6c14e1] to-[#9649dd] flex items-center justify-center">
                                        <PhoneIcon class="fill-white w-5"/>
                                    </div>
                                    <div class="text-lg font-bold">+421 915 718 688</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <form class="flex flex-wrap max-w-3xl mx-auto items-start gap-4" @submit.prevent="redeemCoupon">
                        <TextInput v-model="couponForm.code" :errors="couponForm.errors?.code ? [couponForm.errors.code] : undefined" placeholder="Your coupon code" class="grow" />
                        <TertiaryButton type="submit" :disabled="couponForm.processing">
                            <template v-if="couponForm.processing">
                                <Spinner class="text-current w-5 inline-block animate-spin" />
                            </template>
                            {{ couponForm.processing ? 'Activating...' : 'Activate' }}
                        </TertiaryButton>
                    </form>
                </Card>
            </template>
        </div>
    </BackdropModal>
</template>
