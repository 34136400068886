import { ref } from "vue";

/**
 * This composable holds global state for the paywall modal.
*/

const isOpen = ref(false);
/**
 * Visitor's country ISO code
 * @type {import('vue').Ref<string | null>}
 */
const countryCode = ref(null);
const reason = ref(null);
const userTier = ref(null);
const currentSubscription = ref(null);
const hasBillingAccess = ref(false);

export function usePaywallModal() {
    /**
     * Open the paywall modal.
     * @param {{ countryCode?: string, reason?: string, userTier?: string, hasBillingAccess?: boolean }} data 
     */
    function open(data) {
        isOpen.value = true;
        countryCode.value = data.countryCode;
        reason.value = data.reason;
        userTier.value = data.userTier;
        hasBillingAccess.value = data.hasBillingAccess;
        currentSubscription.value = data.currentSubscription;
    }

    function close() {
        isOpen.value = false;
        reset();
    }

    function reset() {
        countryCode.value = null;
        reason.value = null;
        userTier.value = null;
        hasBillingAccess.value = false;
        currentSubscription.value = null;
    }

    return {
        isOpen,
        open,
        close,
        countryCode,
        reason,
        userTier,
        hasBillingAccess,
        currentSubscription,
    }
}
