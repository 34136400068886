import fetchIntercept from 'fetch-intercept';
import { router } from '@inertiajs/vue3';
import { usePaywallModal } from './usePaywallModal';

const reasons = ['roleplay', 'roleplayBuilder'];

/**
 * The purpose of this composable is to intercept 403 responses and open the paywall modal.
 */
export function usePaywallInterceptor() {
    const paywallModal = usePaywallModal();

    const unregister = fetchIntercept.register({
        response: async function (originalResponse) {
            const response = originalResponse.clone();

            if (response.status === 403 && response.headers.get('Content-Type')?.includes('application/json')) {
                const data = await response.json();
        
                if (reasons.includes(data.reason)) {
                    paywallModal.open({
                        countryCode: data.location?.iso_code,
                        reason: data.reason,
                        userTier: data.userTier,
                        currentSubscription: data.currentSubscription,
                        hasBillingAccess: data.hasBillingAccess,
                    });
                }
            }

            return originalResponse;
        },
    });
    
    router.on('invalid', e => {
        e.preventDefault();
        const response = e.detail.response;

        if (response.status === 403 && reasons.includes(response.data.reason)) {
            const { data } = response;
            
            paywallModal.open({
                countryCode: data.location?.iso_code,
                reason: data.reason,
                userTier: data.userTier,
                hasBillingAccess: data.hasBillingAccess,
                currentSubscription: data.currentSubscription,
            });
        }
    });

    let isFirstNavigation = true;
    router.on('navigate', e => {
        // navigate event is triggered also on very first page load
        // do not close the paywall on the first page load (if it's open)
        if (isFirstNavigation) {
            isFirstNavigation = false;
            return;
        }

        paywallModal.close();
    });
}
